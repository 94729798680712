<template>
  <MainLayout>
    
        <Slider />
      
        <div class="mx-auto max-w-5xl p-4" id="get-started">

            <Categories />

            <NewestCourses />

            <TrendCourses />

      </div>
  </MainLayout>
</template>

<script setup>
    import { onMounted, watchEffect } from '@vue/runtime-core'
    import { useRouter } from 'vue-router';
    import { axiosInstance } from '@/axios'
    import { useAxios } from '@vueuse/integrations/useAxios'

    const router = useRouter()

    onMounted(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.u) {
            localStorage.setItem("AUTH_TOKEN", params.u);
            getCurrentUserInfo();
            location.assign('/')
        }

        if(localStorage.getItem('AUTH_TOKEN')) {
            getCurrentUserInfo();
        }
    })

    const getCurrentUserInfo = () => {
        console.log('geting user info')
        
        axiosInstance.get(`/auth/me`)
        .then(response => {
            console.log(response.data.data)
            localStorage.setItem('AUTH_USER', JSON.stringify(response.data.data))
        })
        .catch(error => {});
    }
</script>

<script>
    import MainLayout from '@/views/layouts/MainLayout.vue'
    import NewestCourses from '@/components/home/NewestCourses.vue'
    import TrendCourses from '@/components/home/TrendCourses.vue'
    import Categories from '@/components/home/Categories.vue'    
    import Slider from '@/components/home/Slider.vue'


    export default {
        components: { MainLayout, NewestCourses, TrendCourses, Categories, Slider },
    }
</script>