import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

createApp(App).use(router).mount('#app')

// require('@/assets/js/jquery-3.6.0.min.js')
// require('@/assets/js/uikit.js')
// require('@/assets/js/tippy.all.min.js')
// require('@/assets/js/simplebar.js')
// require('@/assets/js/custom.js')