<template>
  
    <div id="wrapper" class="horizontal">
        
        <!--  Header  -->
        <header class="is-transparent is-dark border-b backdrop-filter backdrop-blur-2xl" uk-sticky="cls-inactive: is-dark is-transparent border-b">
            <div class="header_inner">
                <div class="left-side">
    
                    <!-- Logo -->
                    <div id="logo">
                        <router-link :to="{ name: 'Home' }">
                            <img src="@/assets/images/logo.png" alt="">
                            <img src="@/assets/images/logo.png" class="logo_inverse" alt="">
                            <img src="@/assets/images/logo.png" class="logo_mobile" alt="">
                        </router-link>
                    </div>
    
                    <!-- icon menu for mobile -->
                    <div class="triger" uk-toggle="target: .header_menu ; cls: is-visible">
                    </div>
    
                    <!-- menu bar for mobile -->
                    <nav class="header_menu lg:mr-10">
                        <ul> 
                            <li> <router-link :to="{ name: 'Home' }" class="active"> الرئيسية </router-link></li>
                            
                            <!-- <li v-if="!authToken" class="block md:hidden">
                                <div>
                                    <a  href="http://landingpage.sd.zain.com/?p=6448858439" class="header_widgets btn btn_action text-green-400 hover:text-green-100 font-semibold " >
                                        إشتراك
                                    </a>
                                </div>
                            </li>
                            <li v-if="authToken" class="block md:hidden">
                                <div>
                                    <a href="" class="text-red-500" @click.prevent="unsubscribe()"> 
                                        <div v-if="!unsubscribeLoading">إلغاء اﻹشتراك</div> 
                                        <div v-else>جارِ إلغاء اﻹشتراك.</div>
                                    </a>
                                </div>
                            </li> -->
                            <!-- <li> -->
                                <!-- <router-link :to="{ name: 'Courses' }" class="active"> الكورسات</router-link>  -->
                                <!-- <div uk-drop="mode: click" class="category-dropdown">
                                    <ul>
                                        <li> <a href="courses.html">  <ion-icon name="newspaper-outline" class="is-icon"></ion-icon> Web Development </a></li>
                                        <li> <a href="courses.html">  <ion-icon name="leaf-outline" class="is-icon"></ion-icon> Mobile App </a> </li>
                                        <li> <a href="courses.html">  <ion-icon name="briefcase-outline" class="is-icon"></ion-icon> Business </a> </li>
                                        <li> <a href="courses.html">  <ion-icon name="color-palette-outline" class="is-icon"></ion-icon> Desings </a></li>
                                        <li> <a href="courses.html">  <ion-icon name="megaphone-outline" class="is-icon"></ion-icon> Marketing </a></li>
                                        <li> <a href="courses.html">  <ion-icon name="camera-outline" class="is-icon"></ion-icon> Photography </a> </li>
                                        <li> <a href="courses.html">  <ion-icon name="accessibility-outline" class="is-icon"></ion-icon> Life Style </a> </li>
                                    </ul>
                                </div> -->
                            <!-- </li> -->
                           <!-- <li> <router-link :to="{ name: 'Profile' }" class="active"> ملفي الشخصي </router-link></li> -->
                            <!-- <li> <a href="episodes.html"> Episode  </a></li> -->
                            <!-- <li> <a href="books.html"> Book  </a></li> -->
                            <!-- <li> <a href="blog.html"> Blog</a></li> -->
                            <!-- <li> <a href="#">Pages</a>
                                <div uk-drop="mode: click" class="menu-dropdown">
                                    <ul>
                                        <li> <a href="pages-pricing.html"> Pricing</a></li>
                                        <li> <a href="pages-faq.html"> Faq </a></li>
                                       <li> <a href="pages-help.html"> Help </a></li>
                                        <li> <a href="pages-terms.html"> Terms </a></li>
                                        <li> <a href="pages-setting.html"> Setting </a></li>
                                        <li> <a href="#"> Development </a>
                                            <div class="menu-dropdown" uk-drop="mode: click;pos:right-top;animation: uk-animation-slide-right-small">
                                                <ul> 
                                                    <li><a href="development-elements.html"> Elements  </a></li>
                                                    <li><a href="development-components.html"> Compounents </a></li>
                                                    <li><a href="development-plugins.html"> Plugins </a></li>
                                                    <li><a href="development-icons.html"> Icons </a></li>
                                                </ul>
                                            </div>  
                                        </li>
                                        <li> <a href="course-intro.html"> Course intro 1 </a>
                                            <div uk-drop="mode: click;pos:right-center" class="menu-dropdown">
                                        <ul>
                                            <li> <a href="pages-pricing.html"> Pricing</a></li>
                                            <li> <a href="pages-faq.html"> Faq </a></li>
                                           <li> <a href="pages-help.html"> Help </a></li>
                                            <li> <a href="pages-terms.html"> Terms </a></li>
                                            <li> <a href="pages-setting.html"> Setting </a></li>
                                        <li> <a href="#"> Development </a>
                                            <div class="menu-dropdown" uk-drop="mode: click;pos:right-top;animation: uk-animation-slide-right-small">
                                                <ul> 
                                                    <li><a href="development-elements.html"> Elements  </a></li>
                                                    <li><a href="development-components.html"> Compounents </a></li>
                                                    <li><a href="development-plugins.html"> Plugins </a></li>
                                                    <li><a href="development-icons.html"> Icons </a></li>
                                                </ul>
                                            </div>  
                                        </li>
                                            <li> <a href="course-intro.html"> Course intro 1 </a></li>
                                            <li> <a href="course-intro-2.html"> Course intro 2 </a></li>
                                        </ul>
                                    </div>
                                </li>
                                        <li> <a href="course-intro-2.html"> Course intro 2 </a></li>
                                    </ul>
                                </div>
                            </li> -->
                        </ul>
                    </nav>
    
                    <!-- overly for small devices -->
                    <div class="overly" uk-toggle="target: .header_menu ; cls: is-visible"></div>
    
                </div>
                <div class="right-side">
    
                    <!-- cart -->
                    <!-- <a href="#" class="header_widgets">
                        <ion-icon name="cart-outline" class="is-icon"></ion-icon>
                    </a>
                    <div uk-drop="mode: click" class="dropdown_cart">
                        <div class="cart-headline"> My Cart 
                            <a href="#" class="checkout">Checkout</a>
                        </div>
                        <ul class="dropdown_cart_scrollbar" data-simplebar>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-1.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4> Learn Angular Beginner to Advanced Fundamentals </h4>
                                </div>
                                <div class="cart_price">
                                    <span> $12.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-1.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4>  Become a Web Developer from Scratch to Advanced </h4>
                                </div>
                                <div class="cart_price">
                                    <span> $19.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-2.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4> Angular Fundamentals for Beginner to advance </h4>
                                </div>
                                <div class="cart_price">
                                    <span> $12.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-3.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4> Ultimate Web Developer Course for Beginners 2020</h4>
                                </div>
                                <div class="cart_price">
                                    <span> $14.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-4.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4> The Complete JavaScript From beginning to advance </h4>
                                </div>
                                <div class="cart_price">
                                    <span> $16.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                            <li>
                                <div class="cart_avatar">
                                    <img src="@/assets/images/courses/img-5.jpg" alt="">
                                </div>
                                <div class="cart_text">
                                    <h4> Become a Web Developer from Scratch to Advanced</h4>
                                </div>
                                <div class="cart_price">
                                    <span> $12.99 </span>
                                    <button class="type"> Remove</button>
                                </div>
                            </li>
                        </ul>
    
                        <div class="cart_footer">
                            <p> Subtotal : $ 320 </p>
                            <h1> Total :  <strong> $ 320</strong> </h1>
                        </div>
                    </div> -->
    
                    <!-- notification -->
                    <!-- <a href="#" class="header_widgets">
                        <ion-icon name="mail-outline" class="is-icon"></ion-icon>
                    </a>
                    <div uk-drop="mode: click" class="header_dropdown"> 
                        <div class="drop_headline">
                            <h4>Notifications </h4>
                            <div class="btn_action">
                                <div class="btn_action">
                                    <a href="#">
                                        <ion-icon name="settings-outline" uk-tooltip="title: Notifications settings ; pos: left"></ion-icon>
                                    </a>
                                    <a href="#">
                                        <ion-icon name="checkbox-outline" uk-tooltip="title: Mark as read all ; pos: left"></ion-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
    
                        <ul class="dropdown_scrollbar" data-simplebar>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-1.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <p> <strong>Adrian Mohani</strong> Like Your Comment On Course
                                            <span class="text-link">Javascript Introduction </span>
                                        </p>
                                        <span class="time-ago"> 2 hours ago </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <p>
                                            <strong>Stella Johnson</strong> Replay Your Comments in
                                            <span class="text-link">Programming for Games</span>
                                        </p>
                                        <span class="time-ago"> 9 hours ago </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-3.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <p>
                                            <strong>Alex Dolgove</strong> Added New Review In Course
                                            <span class="text-link">Full Stack PHP Developer</span>
                                        </p>
                                        <span class="time-ago"> 12 hours ago </span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-1.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <p>
                                            <strong>Jonathan Madano</strong> Shared Your Discussion On Course
                                            <span class="text-link">Css Flex Box </span>
                                        </p>
                                        <span class="time-ago"> Yesterday </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <a href="#" class="see-all">See all</a>
                    </div> -->
    
                    <!-- <div class="hidden md:block" v-if="!authToken">
                        <a href="http://landingpage.sd.zain.com/?p=6448858439" class="header_widgets btn btn_action bg-opacity-90 bg-green-50 px-3 rounded-md text-grey-300 hover:text-green-800 font-semibold" >
                            <div class="px-5">تسجيل الدخول</div>
                        </a>
                    </div>
                    <div class="hidden md:block px-4" v-if="!authToken">
                        <a v-if="!authToken" href="http://landingpage.sd.zain.com/?p=6448858439" class="header_widgets btn btn_action bg-opacity-90 bg-green-50 px-3 rounded-md text-green-400 hover:text-green-800 font-semibold" >
                            <div class="px-5">إشترك اﻵن</div>
                        </a>
                    </div>

                    <div v-if="authToken" class="hidden md:block ml-3">
                        <a href="" @click.prevent="unsubscribe()" class="header_widgets btn btn_action bg-opacity-90 bg-red-50 px-3 rounded-md text-red-400 hover:text-red-500 font-semibold " >                            
                            <div v-if="!unsubscribeLoading" class="px-3">إلغاء اﻹشتراك</div> 
                            <div v-else>جارِ إلغاء اﻹشتراك.</div>
                        </a>
                    </div> -->
                    
                    <!-- messages -->
                    <!-- <a href="#" class="header_widgets">
                        <ion-icon name="notifications-outline" class="is-icon"></ion-icon>
                        <span> 2 </span>
                    </a> -->
                    <!-- <div uk-drop="mode: click" class="header_dropdown">
                        <div class="drop_headline">
                            <h4>Messages </h4>
                            <div class="btn_action">
                                <a href="#">
                                    <ion-icon name="settings-outline" uk-tooltip="title: Message settings ; pos: left"></ion-icon>
                                </a>
                                <a href="#">
                                    <ion-icon name="checkbox-outline" uk-tooltip="title: Mark as read all ; pos: left"></ion-icon>
                                </a>
                            </div>
                        </div>
                        <ul class="dropdown_scrollbar" data-simplebar>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-1.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> John menathon </strong> <span class="time"> 6:43 PM</span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> Zara Ali </strong> <span class="time">12:43 PM</span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-3.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> Mohamed Ali </strong> <span class="time"> Wed</span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-1.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> John menathon </strong> <span class="time"> Sun </span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-2.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> Zara Ali </strong> <span class="time"> Fri </span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <div class="drop_avatar"> <img src="@/assets/images/avatars/avatar-3.jpg" alt="">
                                    </div>
                                    <div class="drop_content">
                                        <strong> Mohamed Ali </strong> <span class="time">1 Week ago</span>
                                        <p> Lorem ipsum dolor sit amet, consectetur </p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <a href="#" class="see-all">See all</a>
                    </div> -->
    
                     <!-- profile -->
                    <ProfileMenu />

                    <!-- <div class="zain-logo mr-2 mt-1 md:mt-0">
                        <img class="" src="@/assets/images/zain_silver.png" height="30px" alt="Zain">
                    </div> -->
    
                </div> 

            </div>
        </header>
        <div class="px-4 py-3 text-center bg-white block md:hidden" >
            <!-- <div v-if="!authToken">
                <a href="http://landingpage.sd.zain.com/?p=6448858439" class="header_widgets btn btn_action bg-opacity-90 bg-green-50 px-4 py-1 rounded-md text-grey-200 hover:text-green-800 font-semibold " >
                    تسجيل الدخول
                </a>

                <a href="http://landingpage.sd.zain.com/?p=6448858439" class="header_widgets btn btn_action bg-opacity-90 bg-green-50 px-4 py-1 rounded-md text-green-400 hover:text-green-800 font-semibold mx-2" >
                    إشترك اﻵن
                </a>
            </div>
            <div v-if="authToken">
                <a href="" @click.prevent="logout()" class="header_widgets btn btn_action bg-opacity-90 bg-indigo-50 px-2 py-1 rounded-md text-grey-800 hover:text-red-500 font-semibold mx-2" >
                    تسجيل الخروج 
                </a>

                <a href="" @click.prevent="unsubscribe()" class="header_widgets btn btn_action bg-opacity-90 bg-red-50 px-2 py-1 rounded-md text-grey-800 hover:text-red-300 font-semibold " >
                    <span v-if="!unsubscribeLoading">إلغاء اﻹشتراك</span> 
                    <span v-else>جارِ إلغاء اﻹشتراك.</span>
                </a>

            </div> -->
        </div>

        <slot></slot>

         <!-- footer -->
        <div class="lg:mt-28 mt-10 mb-7 px-12 border-t pt-7">
            <div class="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
                <p class="capitalize font-medium">  <span>{{ new Date().getFullYear() }}</span> © حقوق النشر محفوظة </p>
                <div class="lg:flex text-gray-700 capitalize hidden">
                    <!-- <a href="#" class="mx-2"> About</a>
                    <a href="#" class="mx-2"> Help</a>
                    <a href="#" class="mx-2"> Terms</a>
                    <a href="#" class="mx-2"> Privacy</a> -->
                </div>
            </div>
        </div>

    </div>
</template>


<script setup>
    import ProfileMenu from '@/views/layouts/layout-components/ProfileMenu.vue'
    import { axiosInstance } from "@/axios"
    import { ref } from "@vue/reactivity"

    const { logout } = require("@/functions")

    const authToken = localStorage.getItem('AUTH_TOKEN')
    const user = JSON.parse(localStorage.getItem('AUTH_USER'))

    const unsubscribeLoading = ref(false)

    const unsubscribe = () => {
        if (unsubscribeLoading.value) return

        let confirmed = window.confirm("سيتم إلغاء إشتراكك من هذه الخدمة، هل انت متأكد?")

        if (confirmed) {
            unsubscribeLoading.value = true
            axiosInstance.post('/auth/unsubscribe', {
                headers: {
                    Authorization: 'Bearer ' + authToken
                }
            })
            .then(data => {
                console.log(data)
                unsubscribeLoading.value = false
                logout()
            })
            .catch(error => {
                console.log(error)
                unsubscribeLoading.value = false
            })
        }
    }

</script>

<style>

</style>