<template>
  <router-view />
</template>

<style>

  /* @import url('@/assets/css/icons');
  @import url('@/assets/css/uikit');
  @import url('@/assets/css/style'); */

</style>